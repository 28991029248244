import React from "react";
import { twMerge } from "tailwind-merge";

const Skeleton = ({ className, ...props }) => {
  return (
    <div
      {...props}
      className={twMerge(
        "bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 bg-opacity-50 rounded-lg h-10 w-full",
        className,
      )}
    ></div>
  );
};

export default Skeleton;
