import React from 'react'

const PopupContainer = ({ children, class_name }) => {
    return (
      <>
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="absolute inset-0 bg-gray-800/30"
            // onClick={() => setShowHideDialog(false)}
          ></div>
          <div
            className={
              "m-4 bg-white/60 2xl:max-w-xl xl:max-w-md w-full p-20 px-10 py-10 h-fit rounded-xl border-2 border-white/40 backdrop-blur-[40px] justify-start gap-0 flex flex-col shadow-lg z-10"
            }
          >
            {children}
          </div>
        </div>
      </>
    );
  };

export default PopupContainer;