// api.js
import axios from "axios"
import * as constant from "../utils/Constants"

const instance = axios.create({
  baseURL: constant.API_URL,
})

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwt_token")
    if (token) {
      config.headers.Authorization = `${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor to handle errors globally
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.clear()
      window.location.href = "/"
    }
    return Promise.reject(error)
  }
)

// Avatars
export const getRecommendations = (payload) =>
  instance.get(`/avatar/recommendations`, payload)
export const getFeatured = (payload) =>
  instance.get(`/avatar/featured`, payload)
export const getExplore = (payload) =>
  instance.get(`/avatar/explore`, { params: payload })
export const getAvatar = (id) => instance.get(`/avatar/details/${id}`)
export const getDistinctCountries = () => instance.get(`/avatar/countries`)
export const getDistinctCategories = () => instance.get(`/avatar/categories`)
export const avatarCPM = (payload) => instance.post(`/avatar/cpm`, payload)
export const getTrending = (payload) =>
  instance.get("/avatar/trending", { params: payload })
export const getUserType = (payload) => instance.get("/avatar/user-type", { params: payload })

export const googleLogin = (payload) => instance.post(`/users/login`, payload)

export const getPrevMessages = (payload) =>
  instance.post(`/users/message`, payload)
export const getRecentChats = (payload) =>
  instance.post(`/users/recent-chats`, payload)
export const getLatestChat = (payload) =>
  instance.post(`/users/latest-chat`, payload)
export const getNewChats = (payload) =>
  instance.post(`/users/new-chats`, payload)

// payment
export const createPayment = (payload) =>
  instance.post(`/payments/create-checkout-session`, payload)
export const checkPaymentStatus = (payload) =>
  instance.post(`/payments/check-payment-status`, payload)
export const cancelSubscription = (payload) =>
  instance.post(`/payments/cancel-subscription`, payload)
export const purchaseCredits = (payload) =>
  instance.post(`/payments/credit-purchase-session`, payload)

export const fetchCoins = (payload) =>
  instance.get("/users/get-coin", { params: payload })
export const fetchTransactions = (payload) =>
  instance.get("/users/transactions", { params: payload })
export const fetchCreditUsage = (payload) =>
  instance.get("/users/credit-usage", { params: payload })
export const createCoins = (payload) =>
  instance.get("/users/create-coin", { params: payload })
export const deductCoins = (payload) =>
  instance.post("/users/deduct-coin", payload)
export const fetchAccessToken = async () => {
  const response = await fetch("/api/hume", {
    method: "POST", // Ensure you're using POST here
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (!response.ok) {
    console.error("Error fetching access token:", response.status)
    throw new Error("Failed to fetch access token")
  }

  const data = await response.json()
  return data.accessToken
}
