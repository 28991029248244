import React, { useState } from "react";
import PopupContainer from "../../common/popup-container";
import Image from "next/image";
import { useGetCountryCode } from "@/src/hooks/query/pricing.query";
import { useBuyCoins } from "@/src/hooks/mutations/pricing.mutation";
import { Loader2 } from "lucide-react";
import Skeleton from "@/src/components/ui/skeleton";

const CREDIT_OPTIONS = [
  { credits: 100, price: { USD: 1.49, INR: 100 } },
  { credits: 200, price: { USD: 2.49, INR: 180 } },
  { credits: 500, price: { USD: 4.99, INR: 400 } },
  { credits: 1000, price: { USD: 9.99, INR: 750 } },
  //   { credits: 5000, price: { US: 12.00, IN: 1000 } },
];

export default function BuyCoinDialog({
  setBuyCoinDialog,
  setBillingMethodDialog,
  balance = 0,
  videoCall=false,
}) {
  const [amount, setAmount] = useState();
  const [currencyAmount, setCurrencyAmount] = useState(0);
  const [selectedOption, setSelectedOption] = useState();
  const { data: countryCode, isLoading } = useGetCountryCode();
  const currency = countryCode === "IN" ? "INR" : "USD";
  const { mutateAsync, isPending } = useBuyCoins();

  console.log();

  return (
    <PopupContainer>
      <button
        type="button"
        onClick={() => setBuyCoinDialog(false)}
        className="size-6 flex absolute right-8 top-7 justify-center items-center"
      >
        <img src="/assets/svg/Close.svg" alt="" width={15} height={15} />
      </button>

      {!videoCall ? (
        <div className="flex justify-center pt-8">
          <div className="w-fit h-[30px] px-4 py-1 bg-zinc-300 rounded-full justify-center items-center gap-2.5 inline-flex">
            <p className="text-base text-indigo-950 font-normal">
              Balance : <b>{balance} credits</b>
            </p>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center pt-9">
          <p className="text-center text-gray-900 font-medium leading-relaxed">
            <span className="text-red-600 font-semibold">
              Video Call is ONLY available for paying users.
            </span>
            <br />
            <span className="font-light">Please purchase credits or subscribe to a plan to make a video call.</span>
          </p>
        </div>
      )}

      <div className="flex flex-col items-center gap-2 w-full pt-8">
        {CREDIT_OPTIONS.map((option, idx) => (
          <button
            key={option.credits}
            onClick={() => {
              setAmount(option.credits);
              if (selectedOption === idx) {
                setSelectedOption();
                setAmount();
                setCurrencyAmount();
              } else {
                setSelectedOption(idx);
                setCurrencyAmount(option.price[countryCode]);
              }
            }}
            className={`w-full h-11 px-6 pt-2 pb-[6.51px] cursor-pointer ${
              selectedOption === idx ? "bg-white/80" : "bg-white/30"
            } hover:bg-white/80 rounded-full shadow border border-white/40 justify-center items-center inline-flex`}
          >
            <div className="grow shrink basis-0 self-stretch justify-between items-center inline-flex">
              <div className="justify-start items-center gap-1 flex">
                <div className="text-center text-indigo-950 text-base font-normal">
                  {option.credits} credits
                </div>
              </div>
              <div className="flex items-end gap-1">
                {isLoading || !countryCode ? (
                  <Skeleton className="h-[1.5rem] w-20" />
                ) : (
                  <span className="text-base text-indigo-950">
                    {countryCode === "IN"
                      ? option.price.INR.toLocaleString("en-US", {
                          style: "currency",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          currency: "INR",
                        })
                      : option.price.USD.toLocaleString("en-US", {
                          style: "currency",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          currency: "USD",
                        })}
                  </span>
                )}
              </div>
            </div>
          </button>
        ))}

        <button
          onClick={() =>
            mutateAsync({
              currency,
              amount: CREDIT_OPTIONS[selectedOption]?.price[currency],
              credits: amount,
            })
          }
          disabled={!amount || isPending}
          className="w-fit shadow__lg h-11 mt-7 px-8 py-2 text-white text-base font-medium bg-gradient-to-b from-neutral-400 to-pink-500 rounded-[39px] shadow justify-center items-center gap-[5.42px] inline-flex disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isPending ? <Loader2 size={20} className="animate-spin" /> : "Buy"}
        </button>
      </div>
    </PopupContainer>
  );
}
