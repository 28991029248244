"use client";
import { createContext, useState } from "react";

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [lastActiveAvatar, setLastActiveAvatar] = useState("");

  const toggleSidebar = () => {
    setSidebarVisible((prevState) => !prevState);
  };

  return (
    <SidebarContext.Provider value={{ sidebarVisible, setSidebarVisible, toggleSidebar,lastActiveAvatar,setLastActiveAvatar }}>
      {children}
    </SidebarContext.Provider>
  );
};
