import { purchaseCredits } from "@/src/apis/api";
import { useMutation } from "@tanstack/react-query";

export function useBuyCoins() {
  return useMutation({
    mutationFn: async ({ amount, currency, credits }) => {
      console.log({ amount, currency, credits });
      const user_id = localStorage.getItem("user_id");
      if (!user_id) {
        document.getElementById("login").click();
        return;
      }
      const { data } = await purchaseCredits({
        user_id: localStorage.getItem("user_id"),
        amount,
        currency,
        credits,
        url: window.location.href,
      });
      
      if (data && data.url) {
        window.location.href = data.url;
      }
    },
  });
}
