"use client"

import { createContext, useState } from 'react';

export const HeaderCreditContext = createContext();

export function HeaderCreditContextProvider({ children }) {
  const [headerCredit, setHeaderCredit] = useState(0);

  return (
    <HeaderCreditContext.Provider value={{ headerCredit, setHeaderCredit }}>
      {children}
    </HeaderCreditContext.Provider>
  );
}