import { useQuery } from "@tanstack/react-query";
import { getExchangeRate } from "@/src/actions/pricing.actions";
import { checkPaymentStatus } from "@/src/apis/api";

export function useCheckPaymentStatus() {
  const user_id = localStorage.getItem("user_id");
  return useQuery({
    queryKey: ["paymentStatus"],
    queryFn: () => {
      if (!user_id) {
        document.getElementById("login").click();
        setStatus(null);
        return;
      }
      return checkPaymentStatus({ user_id });
    },
  });
}

export function useGetCountryCode() {
  return useQuery({
    queryKey: ["countryCode"],
    retry: 0,
    queryFn: () =>
      fetch(process.env.NEXT_PUBLIC_CLOUDFLARE_URL)
        .then((res) => res.json())
        .then((data) => data.country),
  });
}

export function useGetExchangeRate() {
  return useQuery({
    queryKey: ["exchangeRate"],
    queryFn: () => getExchangeRate("USD", "NPR"),
  });
}
