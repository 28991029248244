"use server";
import { headers } from "next/headers";

export async function getIpAddress(ip) {
  const headersList = headers();
  const forwardedFor = headersList.get("x-forwarded-for");
  const realIp = headersList.get("x-real-ip");

  if (forwardedFor) {
    return forwardedFor.split(",")[0].trim();
  }
  if (realIp) {
    return realIp.trim();
  }
  return null;
}

export async function getCountryCode() {
  return "IN";
  try {
    const ip = await getIpAddress();
    if (!ip) {
      return "US";
    }
    const response = await fetch(
      `https://ipapi.co/${ip}/json?key=${process.env.IP_API_KEY}`,
    );
    const data = await response.json();
    console.log("DATA FROM API", data);
    return data?.country_code || "US";
  } catch (err) {
    console.error("ERROR ON COUNTRY CODE LOOKUP:", err);
    return "US";
  }
}

export async function getExchangeRate(from, to) {
  if (!from || !to) {
    return 1;
  }
  const response = await fetch(
    `https://v6.exchangerate-api.com/v6/${process.env.EXCHANGE_RATE_API_KEY}/pair/${from}/${to}`,
  );
  const data = await response.json();
  return data.conversion_rate;
}

// fetch("https://app.ipapi.co/bulk/", {
//   "headers": {
//     "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
//     "accept-language": "en-US,en;q=0.9,ne;q=0.8",
//     "cache-control": "max-age=0",
//     "content-type": "application/x-www-form-urlencoded",
//     "priority": "u=0, i",
//     "sec-ch-ua": "\"Chromium\";v=\"130\", \"Google Chrome\";v=\"130\", \"Not?A_Brand\";v=\"99\"",
//     "sec-ch-ua-mobile": "?1",
//     "sec-ch-ua-platform": "\"Android\"",
//     "sec-fetch-dest": "document",
//     "sec-fetch-mode": "navigate",
//     "sec-fetch-site": "same-origin",
//     "sec-fetch-user": "?1",
//     "upgrade-insecure-requests": "1"
//   },
//   "referrer": "https://app.ipapi.co/bulk/",
//   "referrerPolicy": "same-origin",
//   "body": "csrfmiddlewaretoken=zIzJq4XP9xWFeju2AIewbuKEcRdi0GpOXxqXqkCT1XdtKcA6Q7z3ayc3GxXIn54I&q=8.8.8.8&key=AHKi7lhRTkTFBLOQQfCQw4k4rtkMYpDeDyAj3UwmOFjz3B1J0h&output=json",
//   "method": "POST",
//   "mode": "cors",
//   "credentials": "include"
// });
